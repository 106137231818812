<template>
   <div class="pagination">
      <a v-if="showPrevPage" class="page-5" @click="getFirstPage()">&lt;&lt;</a>
      <a v-if="showPrevPage" class="page-5" @click="getPrevPage()">&#60;</a>
        <a v-for="page in pages" :key="page.number"
        @click="getPage(page.number)"
        class="page-5"
        :disabled="page.isVisible"
        :style="isPageActive(page.number)"
        :aria-label="`Go to page number ${page.number}`" 
        >
        {{ page.number }}
        </a>
      <a v-if="showNextPage" class="page-5" @click="getNextPage()">></a>
      <a v-if="showNextPage" class="page-5" @click="getLastPage()">>></a>
    </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    maxPages: {
        required: true,
    },
    totalPages: {
        required: true
    },
    total: {
        required: true
    },
    perPage: {
        required: true
    },
    currentPage: {
        required: true
    }
  },
  
  computed: {
    showNextPage: function() {
          // if total data count is > amount of results on table and page selected is not last page the show > and >>
          return this.total > this.perPage && this.currentPage !== this.totalPages;
      },

    showPrevPage: function(){
          // if page selected > 1 then show <
          return this.total > this.perPage && this.currentPage !== 1;
      },

    startPage: function() {
      if(this.currentPage === 1) {
        return 1;
      }

       if(this.currentPage === 2) {
        return 2;
      }

      if(this.currentPage === this.totalPages) { 
        return this.totalPages - this.maxPages + 1;
      }

      return this.currentPage - 2;

    },

    endPage: function() {
      
      return Math.min(this.startPage + this.maxPages - 1, this.totalPages);
      
    },

    pages: function() {
      let range = [];

      for(let i = this.startPage; i <= this.endPage; i++) {
        range.push({
          number: i,
          isVisible: i === this.currentPage 
        });
      }
      (this.totalPages === 1) && (range = []);
      return range;
    },

  },
  methods: {
    getFirstPage: function() {
      this.$emit('pageChanged', 1);
    },

    getPrevPage: function() {
      this.$emit('pageChanged', this.currentPage - 1);
    },

    getPage: function(page) {
      this.$emit('pageChanged', page);
    },

    getNextPage: function() {
      this.$emit('pageChanged', this.currentPage + 1);
    },

    getLastPage: function() {
      this.$emit('pageChanged', this.totalPages);    
    },

    isPageActive: function(page) {
      const selected = this.currentPage === page;
      return selected ? {'font-family': 'Roboto-Bold'} : null;
    },
  }
 };
 </script>