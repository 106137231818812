<template>
  <div>
    <div
      class="search-options"
      v-if="
        searchFilter ||
          centerFilter ||
          completedFilter ||
          resultsFilter ||
          orderingFilter
      "
    >
      <div class="box-container">
        <div class="box-half" v-if="searchFilter">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              class="ml-n6"
              @input="getProjects()"
            ></v-text-field>
          </v-card-title>
        </div>
        <div class="box-quarter" v-if="centerFilter || completedFilter">
          <v-select
            v-if="centerFilter"
            class="pt-4 pl-16"
            v-model="center"
            :items="centers"
            label="Center"
            outlined
            hide-details
            @change="getProjects()"
          ></v-select>
          <v-switch
            v-if="completedFilter"
            @change="getProjects()"
            v-model="completed"
            label="Include completed projects"
            inset
          ></v-switch>
        </div>
        <div class="box-quarter" v-if="resultsFilter || orderingFilter">
          <v-select
            v-if="resultsFilter"
            class="pt-4 pl-16"
            v-model="resultsProjects"
            :items="numberOfResults"
            label="Results Per Page"
            outlined
            hide-details
            @change="getProjects()"
          ></v-select>
          <v-select
            v-if="orderingFilter"
            class="pt-4 pl-16"
            v-model="ordering"
            :items="orderings"
            label="Order By"
            outlined
            hide-details
            @change="getProjects()"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="results-count" v-if="showResultsCount">
      Showing <strong>{{ resultCount }}</strong> Project<span
        v-if="resultCount != 1"
        >s</span
      >
    </div>
    <div id="projects-container">
      <v-card
        class="content-panel box-third project-panel"
        style="display: inline-block;"
        v-for="project in projects.results"
        :key="project.id"
      >
        <a class="no-underline" :href="`/projects/${project.id}`">
          <v-card-title
            class="panel-title justify-center text-h6 link-card-title white--text"
            :style="{ backgroundColor: getHeaderBackgroundColor(project) }"
            style="word-break: break-word;"
          >
            <div class="flex-column">
              <span class="mb-n2">{{ project.name }}</span>
              <div class="d-flex justify-center" style="white-space: nowrap;">
                <span
                  v-if="
                    keyContactProjects &&
                      keyContactProjects.includes(project.id)
                  "
                  class="text-overline key-contact mr-4"
                  ><v-icon color="var(--aqua)" x-small class="mr-1"
                    >mdi-account</v-icon
                  >KEY CONTACT</span
                >
                <span v-if="project.subgrant" class="text-overline key-contact"
                  ><v-icon color="var(--yellow)" x-small class="mr-1"
                    >mdi-trophy</v-icon
                  >SUBGRANT</span
                >
              </div>
            </div>
          </v-card-title>
          <v-card-text class="grey--text pt-4 font-weight-medium">
            <span class="text-caption font-weight-bold">{{
              getFormattedDate(project)
            }}</span>
            <p class="text-body-1 card-text-scrollable">
              {{ getProjectContent(project) }}
            </p>
            <v-card-text class="pt-5 mb-n6 ml-n4">
              <v-chip
                v-if="project.award_center"
                :class="[
                  project.award_center === 'BCPI' ? 'bcpi-chip' : 'govex-chip',
                  'mt-n12 tag-chip mr-1',
                ]"
                small
              >
                {{ project.award_center }}
              </v-chip>
              <v-chip
                v-if="project.subgrant && project.subgrant_amount"
                class="mt-n12 mr-1 tag-chip subgrant-chip"
                small
              >
                ${{ Number(project.subgrant_amount).toLocaleString() }}
              </v-chip>
              <v-chip
                v-if="project.priority_area"
                class="mt-n12 custom-margin tag-chip priority-area-chip"
                small
              >
                {{ project.priority_area }}
              </v-chip>
            </v-card-text>
          </v-card-text>
        </a>
      </v-card>
    </div>
    <br />
    <Pagination
      :maxPages="Math.min(Math.ceil(resultCount / resultsProjects), 5)"
      :totalPages="Math.ceil(resultCount / resultsProjects)"
      :total="resultCount"
      :perPage="resultsProjects"
      :currentPage="currentPage"
      @pageChanged="getProjects($event)"
    />
  </div>
</template>
<style>
.input-box {
  width: 90%;
}
label[for="search"] {
  height: 25px;
}
#search {
  position: relative;
  top: -6px;
}
.content-panel.v-card .v-card__text p.text-body-1 {
  font-size: 13px !important;
  line-height: 18px;
}
.key-contact {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  text-align: center; /* Center the text */
}
</style>
<script>
import Pagination from "../components/Pagination";
import moment from "moment";

export default {
  props: {
    endPoint: {
      type: String,
      required: false,
      default: "projects?",
    },
    searchFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    resultsFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    completedFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    orderingFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    centerFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    completed: {
      type: Boolean,
      required: false,
      default: true,
    },
    keyContactProjects: {
      type: Array,
      required: false,
      default: () => [],
    },
    showResultsCount: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: { Pagination },
  data() {
    return {
      projects: {},
      resultsProjects: "15",
      orderings: [
        { text: "", value: "date" },
        { text: "Name", value: "name" },
      ],
      center: "",
      search: "",
      centers: ["", "GovEx", "BCPI"],
      numberOfResults: ["15", "30", "60", "120"],
      offset: 0,
      currentPage: 1,
      ordering: "date",
    };
  },
  created() {
    this.center = this.mapCenterFromQueryString(this.$route.query.center);
    this.getProjects();
  },
  computed: {
    resultCount() {
      return this.projects.count;
    },
  },
  watch: {
    projects(currentValue) {
      this.$emit("projects", currentValue);
    },
    beforeMount() {
      this.$emit("projects", this.projects);
    },
    center(newCenter) {
      this.$router.push({
        query: {
          center: newCenter,
        },
      });
    },
  },
  methods: {
    getProjects: function(page = 1) {
      this.currentPage = page;
      this.offset = (this.currentPage - 1) * this.resultsProjects;

      this.$api
        .get(
          `${this.endPoint}ordering=${this.ordering}&limit=${this.resultsProjects}&center=` +
            `${this.center}&search=${this.search}&completed=${this.completed}&offset=${this.offset}`
        )
        .then((response) => {
          this.projects = response.data;
        });
    },
    // Get the formatted date based on the project's start_date or completed_date
    getFormattedDate(project) {
      const { start_date, completed_date, status } = project;

      if (!start_date && !completed_date) {
        return "None";
      }

      const formattedStartDate = this.$options.filters.formatDate(start_date);
      const formattedCompletedDate = this.$options.filters.formatDate(
        completed_date
      );

      if (status === "COMP" && completed_date) {
        const startDateMoment = moment(start_date);
        const completedDateMoment = moment(completed_date);

        if (
          startDateMoment.isSame(completedDateMoment, "month") &&
          startDateMoment.isSame(completedDateMoment, "year")
        ) {
          return `${startDateMoment.format(
            "MMM D"
          )}-${completedDateMoment.format("D")}, ${completedDateMoment.format(
            "YYYY"
          )}`;
        } else {
          return `${formattedStartDate} - ${formattedCompletedDate}`;
        }
      } else if (status === "COMP" && !completed_date) {
        return `${formattedStartDate} - None`;
      } else {
        return `Started on ${formattedStartDate}`;
      }
    },

    getProjectContent(project) {
      return project.status === "INP"
        ? project.problem_statement || project.summary
        : project.outcome
        ? project.outcome
        : project.summary || "None";
    },

    // Get the header background color based on the project's is_completed field
    getHeaderBackgroundColor(project) {
      return project.status === "INP" ? "var(--green)" : "var(--dark-grey)";
    },
    mapCenterFromQueryString(queryCenter) {
      if (!queryCenter) {
        return "";
      }
      const centerMap = {
        govex: "GovEx",
        bcpi: "BCPI",
        partner: "Partner",
      };
      return centerMap[queryCenter.toLowerCase()] || "";
    },
  },
};
</script>
